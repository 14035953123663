<template>
  <div class="header" @click="$emit('click', $event)">
    <div class="header_container">
      <div class="back">完成</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DoneButton",
};
</script>

<style lang="scss" scoped>
.header_container {
  // padding: 1rem 1rem 1rem 1rem;
  display: flex;
  align-items: center;
  background-color: white;

  .back {
    background-color: var(--orange);
    color: white;
    display: block;
    padding: .35rem .4rem;
    border-radius: .85rem;
    min-width: 3.5rem;
  }
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
}

// .header_container {
//   display: flex;
//   justify-content: end;

// }
</style> 