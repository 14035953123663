<template>
  <div class="header" @click="$emit('click', $event)">
    <div class="header_container">
      <div class="back">取消</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CancelButton",
};
</script>

<style lang="scss" scoped>
.header_container {
  display: flex;
  align-items: center;
  background-color: white;

  .back {
    color: var(--black);
    display: block;
    border-radius: 0.85rem;
    font-weight: bold;
  }
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
}
</style>