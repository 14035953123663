<template>
  <Container theme="dark" :padding="false">
    <HeaderBtn>
      <template v-slot:leftbtn>
        <CancelButton @click="cancelHandler"></CancelButton>
      </template>
      <div class="header_nick">
        设置昵称
      </div>
      <template v-slot:rightbtn>
        <DoneButton @click="doneHandler"></DoneButton>
      </template>
    </HeaderBtn>
    <div class="w_100">
      <div class="item border_bottom" v-for="form in formData" :key="form.label">
        <div class="d_flex justify_content_between">
          <input type="text" class="form_control" :placeholder="form.placeholder" v-model="tempName" maxlength="8" />
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import Container from "../components/Container";
import DoneButton from "../components/DoneButton";
import CancelButton from "../components/CancelButton";
import HeaderBtn from "@/components/HeaderBtn";

import { mapActions, mapState } from "vuex";
// import Header from "@/components/Header.vue";

export default {
  name: "security",
  components: {
    Container,
    HeaderBtn,
    DoneButton,
    CancelButton
    // SlotHeader
    // Header
  },
  data() {
    return {
      formData: [
        {
          label: "",
          key: "nickName",
          type: "text",
          placeholder: "在此输入昵称",
          value: ""
        }
      ],
      tempName: ""
    };
  },
  watch: {
    nickName: {
      immediate: true,
      handler(newValue) {
        this.tempName = newValue;
      }
    }
  },
  computed: {
    ...mapState("users", ["usersData"]),
    nickName() {
      return this.usersData ? this.usersData.nickName : "";
    }
  },
  methods: {
    ...mapActions("users", ["getUsers", "updateUsers"]),
    ...mapActions("users", ["getUsers"]),
    doneHandler() {
      this.updateUsers({ nickName: this.tempName });
      this.$router.go(-1);
    },
    cancelHandler() {
      this.$router.go(-1);
    }
  },
  mounted() { }
};
</script>

<style lang="scss" scoped>
.w_100 {
  width: 100%;
}

.d_flex {
  display: flex;
  align-items: center;
  width: 100%;
}

.justify_content_between {
  justify-content: space-between;
}

.item {
  width: 100%;
  box-sizing: border-box;
  box-sizing: border-box;
}

.icon {
  display: block;
  width: 1rem;
  transform: translateY(0.1rem);

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.border_bottom {
  border-bottom: 0.75px solid #d4d4d481;
}

.form_label {
  margin-right: 0.5rem;
}

.form_control {
  flex: 1;
  padding: 1.2rem 1.2rem;
  border: none;
}

::placeholder {
  color: var(--light-gray);
  font-size: 1rem;
}

input:focus {
  outline: none;
}

.header_nick {
  display: flex;
}
</style>
