<template>
  <div class="slot_header">
    <div class="slot_header_header">
      <div class="">
        <slot name="leftbtn"></slot>
      </div>
      <div>
        <slot></slot>
      </div>
      <div class="">
        <slot name="rightbtn"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SwitchHeader",
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
    showBackIcon: {
      type: Boolean,
      default: true,
    },
    imageName: {
      type: String,
      default: 'images/public/ic_my_money_course.webp',
    }
  },
  computed: {
    title() {
      return this.$route.meta.name;
    },
  },
  methods: {
    back() {
      this.$emit("onBack");
    },
    handleIconClick() {
      this.$emit("onIconClick");
    },
  },
  created() { },
};
</script>

<style lang="scss" scoped>
.slot_header {
  background: var(--white);
  border-bottom: 1px solid var(--light-gray);

  &_header {
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_back {
      // width: 1.5em;
      // height: auto;
    }

    &_content {
      flex: auto 1 0;
      font-size: 1.3rem;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: baseline;
    }

  }
}
</style>
